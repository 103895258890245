@font-face {
  font-family: 'Codec Pro'; /* The name you'll use in CSS */
  src: url('./assets/fonts/codec-pro.regular.ttf') format('truetype'); /* Path to the .ttf file */
  font-weight: normal; /* Adjust weight if you have multiple font weights */
  font-style: normal; /* Adjust style if you have italic or other styles */
}



/* @import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@600&display=swap"); */
body {
  margin: 0;
  font-family: 'Codec Pro', sans-serif !important;
}


